.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
}

.AppHeader {
  text-align: center;
  font-family: 'Parisienne', cursive;
}

.AppSubHeader {
  text-align: center;
  font-family: 'Cormorant SC', serif;
}

.DetailsSubHeader {
  font-family: 'Parisienne', cursive;
}

.HeaderImage {
  margin: -20px 0px 0px 0px;
  width: 100px;
  height:100px;
}

.Photo {
  max-width: 100%;
  padding: 20px;
}

.NavIcon{
  color: black;
}

.NavMenuItem {
  font-family: 'Cormorant Garamond', serif;
}

body {
  background-color: #FBFAF5;
}